
import { Options, Vue } from "vue-class-component";
import { ILeave } from "@/types/Leave";
import { ILeaveRequest } from "@/types/Leave";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import AuthStore from "@/store/auth-store";
import moment from "moment";
import CancelDialog from "@/components/CancelDialog.vue";
import { ISetting } from "@/types/Setting";
import { IEmployeeLeaveRecord } from "@/types/Leave";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { notify } from "@/services/helpers";
import ProfileImage from "@/views/employees/ProfileImage.vue";

@Options({
  components: {
    ProfileImage,
    CancelDialog,
    ConfirmationModal,
  },
  data: function () {
    return {
      header: true,
    };
  },
})
export default class Dashboard extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  protected authStore = AuthStore;
  leaveRequests: ILeaveRequest[] = [];
  employeeOnLeaves: ILeave[] = [];
  upcomingLeaves: ILeave[] = [];
  employeeSetting: ISetting = {
    id: 0,
    key: "",
    value: "",
    description: "",
    organisationId: 0,
  };
  leavesAvailed = 0;
  isAdmin = false;
  isManager = false;
  employeeLeaveRecord: IEmployeeLeaveRecord = {
    totalLeaves: 0,
    leavesAvailed: 0,
    bookAhead: 0,
    balanceLeaves: 0,
  };
  btnLoading = -1;

  employeeCount = false;
  departmentCount = false;
  adminCount = false;
  shiftCount = false;
  async initialize(): Promise<void> {
    const tokenData = this.authStore.getTokenData();
    const userRoles = tokenData.Roles;
    if (userRoles == "Manager") {
      this.isManager = true;
    }
    if (
      userRoles == "Admin" ||
      userRoles == "Org Admin" ||
      userRoles == "Manager"
    ) {
      this.isAdmin = true;

      // Employee
      await this.workForceService
        .get<ILeave[]>(`/employee`, false)
        .then((response: any) => {
          if (response.content.totalCount <= 1) {
            this.employeeCount = true;
          }
        });

      // Department
      await this.workForceService
        .get<ILeave[]>(`/Departments`, false)
        .then((response: any) => {
          if (response.content.totalCount <= 1) {
            this.departmentCount = true;
          }
        });

      // Admin
      await this.workForceService
        .get<ILeave[]>(`/employee?Role=Admin&Role=Org Admin`, false)
        .then((response: any) => {
          if (response.content.totalCount == 1) {
            this.adminCount = true;
          }
        });

      // Shift
      await this.workForceService
        .get<ILeave[]>(`/Shifts`, false)
        .then((response: any) => {
          if (response.content.length <= 1) {
            this.shiftCount = true;
          }
        });

      await this.workForceService
        .get<ILeave[]>(`/leave/allEmployees`, false)
        .then((response: any) => {
          this.leaveRequests = response.content;
        });

      await this.workForceService
        .get<ILeave[]>(`/leave/EmployeesOnLeaves`, false)
        .then((response: any) => {
          this.employeeOnLeaves = response.content;
        });
    }

    await this.workForceService
      .get<ILeave[]>(`/leave/Employees/Upcoming?numberOfDays=10`, false)
      .then((response: any) => {
        this.upcomingLeaves = response.content;
      });

    await this.workForceService
      .get<ILeave[]>(`/settings/LeavesQuota`, false)
      .then((response: any) => {
        this.employeeSetting = response.content;
      });

    await this.workForceService
      .get<ILeave[]>(`/leave/LeavesAvailed`, false)
      .then((response: any) => {
        this.leavesAvailed = response.content;
      });

    await this.workForceService
      .get<IEmployeeLeaveRecord>(`/leave/EmployeeLeaveRecord`, false)
      .then((response: any) => {
        this.employeeLeaveRecord = response.content;
      });
  }
  loading = false;
  async created(): Promise<void> {
    this.loading = true;
    await this.initialize();
    this.loading = false;
  }

  formateDate(leaveDate: any): any {
    return moment(new Date(leaveDate)).format("Do-MMM-YY");
  }

  async approveLeave(id: number): Promise<any> {
    const modal = await (this.$refs["confirmDialogue"] as any).show({
      title: "Attention!",
      message: "Are you sure you want Approve Request?",
      okButton: "Approve",
      cancelButton: "Cancel",
      icon: "info",
      type: "confirmation",
    });
    if (modal) {
      this.btnLoading = id;
      await this.workForceService
        .put<void>(`/leave/approve/${id}`, id as number)
        .then((response) => {
          this.btnLoading = -1;
          this.initialize().then(() => {
            if (!response.isError) {
              notify(
                "Leave request approved successfully.",
                "Approved",
                "success"
              );
            } else {
              notify(response.errors, "Error", "danger");
            }
          });
        });
    } else {
      return false;
    }
  }

  async cancelLeave(id: number, LRIndex: number) {
    const modal = await (this.$refs["CancelDialogue"] as any).show({
      leaveId: id,
    });
    if (modal) {
      notify("Leave request rejected successfully", "Rejected", "success");
      this.leaveRequests.splice(LRIndex, 1);
    }
  }

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  hasProfileImage(employee: any) {
    if (employee.profileImage) {
      return employee.profileImage;
    }
    return "";
  }
}
